<template>
    <div class="actual-cate-manage">
        <CateTemplate v-bind="templateConfig"></CateTemplate>
    </div>
</template>

<script>
import CateTemplate from "components/admin/classificationManage/CateTemplate";
import { operateTheoryCate, getTheoryCateData, deleteTheoryCate } from "@/utils/apis";
export default {
    name: "ActualCateManage",
    data() {
        return {
            templateConfig: {
                type: 3,
                operateCate: operateTheoryCate,
                getData: getTheoryCateData,
                deleteCate: deleteTheoryCate,
            },
        };
    },
    components: {
        CateTemplate,
    },
    computed: {},
    methods: {},
    mounted() {},
};
</script>

<style scoped lang="scss">
.actual-cate-manage {
    padding: 0 20px;
    height: 100%;
}
</style>
